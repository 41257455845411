const GalleryPage = () => {
  return (
    <section>
      <h2>Gallery</h2>
      <br />
      <div id="gallery">
        <div>
          <img src="/img/gallery001.jpeg" alt="001" />
          <a href="#lightbox-1">001</a>
        </div>
        <div>
          <img src="/img/gallery002.jpeg" alt="002" />
          <a href="#lightbox-2">002</a>
        </div>
        <div>
          <img src="/img/gallery003.jpeg" alt="003" />
          <a href="#lightbox-3">003</a>
        </div>
        <div>
          <img src="/img/gallery004.jpeg" alt="004" />
          <a href="#lightbox-4">004</a>
        </div>
        <div>
          <img src="/img/gallery005.jpeg" alt="005" />
          <a href="#lightbox-5">005</a>
        </div>
        <div>
          <img src="/img/gallery006.jpeg" alt="006" />
          <a href="#lightbox-6">006</a>
        </div>
        <div>
          <img src="/img/gallery007.jpeg" alt="007" />
          <a href="#lightbox-7">007</a>
        </div>
        <div>
          <img src="/img/gallery008.jpeg" alt="008" />
          <a href="#lightbox-8">008</a>
        </div>
        <div>
          <img src="/img/gallery009.jpeg" alt="009" />
          <a href="#lightbox-9">009</a>
        </div>
        <div>
          <img src="/img/gallery010.jpeg" alt="010" />
          <a href="#lightbox-10">010</a>
        </div>
        <div>
          <img src="/img/gallery011.jpeg" alt="011" />
          <a href="#lightbox-11">011</a>
        </div>
        <div>
          <img src="/img/gallery012.jpeg" alt="012" />
          <a href="#lightbox-12">012</a>
        </div>
        <div>
          <img src="/img/gallery013.jpeg" alt="013" />
          <a href="#lightbox-13">013</a>
        </div>
        <div>
          <img src="/img/gallery014.jpeg" alt="014" />
          <a href="#lightbox-14">014</a>
        </div>
        <div>
          <img src="/img/gallery015.jpeg" alt="015" />
          <a href="#lightbox-15">015</a>
        </div>
        <div>
          <img src="/img/gallery016.jpeg" alt="016" />
          <a href="#lightbox-16">016</a>
        </div>
        <div>
          <img src="/img/gallery017.jpeg" alt="017" />
          <a href="#lightbox-17">017</a>
        </div>
        <div>
          <img src="/img/gallery018.jpeg" alt="018" />
          <a href="#lightbox-18">018</a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-1">
        <div className="content">
          <img src="/img/gallery001.jpeg" alt="001" />
          <div className="title">No. 001 Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-2">
        <div className="content">
          <img src="/img/gallery002.jpeg" alt="002" />
          <div className="title">No. 002: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-3">
        <div className="content">
          <img src="/img/gallery003.jpeg" alt="003" />
          <div className="title">No. 003: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-4">
        <div className="content">
          <img src="/img/gallery004.jpeg" alt="004" />
          <div className="title">No. 004: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-5">
        <div className="content">
          <img src="/img/gallery005.jpeg" alt="005" />
          <div className="title">No. 005: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-6">
        <div className="content">
          <img src="/img/gallery006.jpeg" alt="006" />
          <div className="title">No. 006: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-7">
        <div className="content">
          <img src="/img/gallery007.jpeg" alt="007" />
          <div className="title">No. 007: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-8">
        <div className="content">
          <img src="/img/gallery008.jpeg" alt="008" />
          <div className="title">No. 008: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-9">
        <div className="content">
          <img src="/img/gallery009.jpeg" alt="009" />
          <div className="title">No. 009: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-10">
        <div className="content">
          <img src="/img/gallery010.jpeg" alt="010" />
          <div className="title">No. 010: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-11">
        <div className="content">
          <img src="/img/gallery011.jpeg" alt="011" />
          <div className="title">No. 011: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-12">
        <div className="content">
          <img src="/img/gallery011.jpeg" alt="012" />
          <div className="title">No. 012: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-13">
        <div className="content">
          <img src="/img/gallery013.jpeg" alt="013" />
          <div className="title">No. 013: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-14">
        <div className="content">
          <img src="/img/gallery014.jpeg" alt="014" />
          <div className="title">No. 014: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-15">
        <div className="content">
          <img src="/img/gallery015.jpeg" alt="015" />
          <div className="title">No. 015: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-16">
        <div className="content">
          <img src="/img/gallery016.jpeg" alt="016" />
          <div className="title">No. 016: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-17">
        <div className="content">
          <img src="/img/gallery017.jpeg" alt="017" />
          <div className="title">No. 017: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
      <div className="lightbox" id="lightbox-18">
        <div className="content">
          <img src="/img/gallery018.jpeg" alt="018" />
          <div className="title">No. 018: Olive tree - Monte Peru</div>
          <a className="close" href="#gallery">
            X
          </a>
        </div>
      </div>
    </section>
  );
};

export default GalleryPage;
