
export const loadHarvestData = async (tokenID) => {
    let harvestData = null;
    try {
        const res = await fetch(`/data/HarvestData.json`);
        harvestData = await res.json();
    }
    catch(error) {
        console.error("Cannot load NFT", error);
    }
    return {
        ...harvestData
    };
}