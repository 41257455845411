import NFTMinter from "./components/ui/NFTMinter";

const BuyNFTPage = (props) => {
  const { contractAddress, account } = props;

  return (
    <div className="purchase-nft">
      <NFTMinter contractAddress={contractAddress} account={account} />
      <div className="content-wrapper">
        <h2>Buy an olive tree as NFT</h2>
        <p>
          We are providing you with the unique opportunity to buy an olive tree
          NFT called the <strong>Olive NFTree</strong>.
        </p>
        <p>
          With this Olive NFTree you can help us maintain and harvest the olive
          trees. A large part of the harvesting profits are paid out to the
          NFTree holders. The payment will be done in BTC since that is the
          number one coin with the best survival chance. So not only do you own
          very special NFT's that can be kept or sold with a profit. You also
          share in the actual profit of the tree itself! This is a unique
          concept that has never been done before. A multichain profitable
          combination that also helps preserve the glory of Mother Earth.
        </p>
        <p></p>
        <br />
        <h2>Proof of Harvest</h2>
        <p>
          Normally an olive tree gets harvested every year unless it needs to be
          radically pruned to get back into a better shape for harvesting. Then
          the tree needs to heal and deflect its energy to the right kind of
          branches and olive production. The Olive NFTree is going to work with
          the unique principle of <strong>Proof of Harvest</strong> (PoH).
        </p>
        <p>
          As the world globalizes, asset ownership is no longer limited to
          institutional investors. The advent of tokenization and
          fractionalization of assets and non-fungible tokens (NFTs) has made it
          easier for retail investors to purchase a stake in a real-world asset.
          This technology is starting to be used to craft incentive models that
          reward individuals for the profits of harvesting an asset. We call it
          proof of harvest. One popular way to incentivize harvest and reward
          individuals for their contributions is through NFTs. Non-fungible
          tokens are unique digital assets that cannot be replicated, remixed,
          or reused. Like tokens, NFTs are tradeable and hold a value, but also
          have additional unique characteristics that make them distinct from
          typical cryptocurrencies. Unlike standard tokens, NFTs can have
          artwork, music, or other digital memorabilia embedded in them and can
          also represent physical assets.
          <br />
          Proof of harvest is an innovative new platform that leverages NFTs to
          encourage individuals to participate in the asset harvesting process,
          and the rewards they receive depend on the profits they generated. The
          platform works by dividing ownership of each asset into tokens, and
          when individuals help harvest the asset, they receive individual
          rewards depending on their role in the process. For example, if
          someone maintains or operates the harvesting process, they could
          receive a higher reward than if they merely acted as a custodian or
          provided resources. In addition to incentivizing harvest and rewarding
          contributors, proof of harvest gives individuals ownership in a
          real-world asset by fractionalizing it. By fractionalizing an asset,
          it becomes easier for smaller investors to purchase a portion of the
          asset, allowing more people to invest in a larger array of
          investments. This allows for increased liquidity, as the asset can be
          traded on exchanges.
          <br />
          It also allows for greater levels of transparency, as the value of the
          asset can be tracked and audited in real time. Proof of harvest is an
          example of how blockchain technology can be used to revolutionize the
          way we invest, allowing for more individuals to reap the benefit of
          asset investments. With the ability to fractionalize assets, create
          unique NFTs to help incentivize asset harvesting, and reward
          participants for their roles, the platform is well-positioned to
          become a viable investment option for the future.
        </p>
        <p>
          PoH is a principle where each olive tree will get a unique identifier,
          the tokenID. The moment the tree is harvested and the olives brought
          to the press an entry into the Olive Token smart contract will be made
          for that particular tree. The total benefits from the harvest will be
          calculated and the percentage covered by the Olive NFTree will be put
          into the BTC wallet that you can specify after buying the NFTree.
        </p>
      </div>
    </div>
  );
};

export default BuyNFTPage;
