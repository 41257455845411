import VerticalCarousel from "./components/ui/VerticalCarousel";

const AboutUsPage = () => {
  return (
    <div>
      <VerticalCarousel />
      <div className="content-wrapper">
        <h2>About us</h2>
        <p>
          Welcome. We are a couple of crypto specialist. Next to this we share a
          true love for this planet we live on. We have been present in Portugal
          for a while now. And what we see is that a lot of areas with beautiful
          fruit and olive trees are neither maintained nor harvested because of
          the dwindling population in rural areas. What we want to create is an
          initiative funded through NFTs to enable proper maintenance of ancient
          fruit and olive trees. We want to enable the return of the harvesting
          and care taking of those magnificent trees. Tender, loving careness
          for one of the greatest gifts of Mother nature, the tree. And we
          welcome you to help us with the preservation and restoration. Because
          this earth with all its beauty is something that our children and many
          generations to come should be able to enjoy in its fullest glory. And
          if we all are able to create some sort of profit from doing so, then
          everybody who's involved can share the benefits from the restoration
          and preservation: from landowner to NFTree holder.{" "}
        </p>
        <br />
        <h2>Monte Peru & white olives</h2>
        <p>
          Our inaugural endeavor involves the maintenance of an expansive olive
          grove nestled on the slopes of Monte Peru, a majestic mountain. The
          terrain is terraced, providing a conducive environment for the olive
          trees to flourish. For several decades, the majority of these trees
          have been well-tended, and some hold rich cultural significance as
          ancient specimens.
          <br />
          What further sets this olive grove apart is the presence of an
          exclusive selection of white olives. These olives are scarce and
          highly coveted, and the oil pressed from them has historically been
          employed to anoint royalty. This unique olive oil variety is
          challenging to source globally, making our acquisition all the more
          noteworthy.
          <br />
          To produce excellent quality olives, one must ensure that the trees
          receive optimum care and attention. Pruning the trees annually is
          crucial for their well-being, and it is advisable for the first year
          to not expect a significant yield of olives. After the second year,
          however, the tree produces ample amounts of premium olives, ideal for
          pressing high-quality olive oil.
        </p>
      </div>
    </div>
  );
};

export default AboutUsPage;
