const Footer = () => {
  return (
    <footer>
      <ul className="wrapperFooter">
        {/*}      <li className="icon facebook">
        <span className="tooltip">Facebook</span>
        <span>
          <i className="fab fa-facebook-f"></i>
        </span>
  </li> */}
        <li className="icon twitter">
          <span className="tooltip">Twitter</span>
          <span>
            <i
              className="fab fa-twitter"
              onClick={() => {
                window.open("https://twitter.com/OliveNFTree");
              }}
            ></i>
          </span>
        </li>
        <li className="icon instagram">
          <span className="tooltip">Instagram</span>
          <span>
            <i
              className="fab fa-instagram"
              onClick={() => {
                window.open(
                  "https://instagram.com/olivenftree?igshid=YmMyMTA2M2Y="
                );
              }}
            ></i>
          </span>
        </li>
        {/* <li className="icon github">
          <span className="tooltip">Github</span>
          <span>
            <i className="fab fa-github"></i>
          </span>
        </li>
        <li className="icon youtube">
          <span className="tooltip">Youtube</span>
          <span>
            <i className="fab fa-youtube"></i>
          </span>
            </li> */}
      </ul>
    </footer>
  );
};

export default Footer;
