const NFTHolderList = (props) => {
  const { nftHolders } = props;
  const arr = nftHolders.split(",");
  const map = arr.reduce(
    (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
    new Map()
  );
  const arrHolders = [...map.keys()];
  const arrAmounts = [...map.values()];

  return (
    <div id="NFTHolders">
      <table>
        <thead>
          <tr className="tr-head">
            <th>#</th>
            <th>NFT holder</th>
            <th># of NFTrees</th>
          </tr>
        </thead>
        <tbody>
          {arrHolders.map((holder, i) => (
            <tr key={holder}>
              <td>{i + 1}</td>
              <td>{holder}</td>
              <td style={{ textAlign: "center" }}>{arrAmounts[i]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NFTHolderList;
