import { useEffect, useState } from "react";
import NFTHolderList from "./NFTHolderList";
import BTCWalletHolderList from "./BTCWalletHolderList";
import { loadHarvestData } from "../../utils/load-harvest-data";
import { useSDK } from "@thirdweb-dev/react";
import contractABI from "../../contracts/GenerateNFTOnlinePresaleReveal.json";
import { ethers } from "ethers";

const AdminPanel = (props) => {
  const { contractAddress, account } = props;
  const [open, setOpen] = useState(false);
  const [harvested, setHarvested] = useState(false);
  const [maxPerMint, setMaxPerMint] = useState(0);
  const [maxPerWallet, setMaxPerWallet] = useState(0);
  const [revealed, setRevealed] = useState(false);
  const [cost, setCost] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [supply, setSupply] = useState(0);
  const [commission, setCommission] = useState(0);
  const [owner, setOwner] = useState(null);
  const [nftsOwnedWallets, setNFTsOwnedWallets] = useState("");
  const [btcWalletHolders, setBTCWalletHolders] = useState("");
  const [harvestKilosPerLiter, setHarvestKilosPerLiter] = useState(0);
  const [harvestKilosPerTree, setHarvestKilosPerTree] = useState(0);
  const [harvestPercentageGreen, setHarvestPercentageGreen] = useState(0);
  const [harvestPercentageWhite, setHarvestPercentageWhite] = useState(0);
  const [harvestPricePerLiter, setHarvestPricePerLiter] = useState(0);
  const [harvestTotalKilosHarvested, setHarvestTotalKilosHarvested] =
    useState(0);
  const [baseUri, setBaseUri] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState(0);

  const sdk = useSDK();
  const [contract, setContract] = useState();

  useEffect(() => {
    const initContract = async () => {
      const contract = await sdk.getContractFromAbi(
        contractAddress,
        contractABI.abi
      );
      setContract(contract);
    };
    sdk && initContract();
  }, [sdk, contractAddress]);

  useEffect(() => {
    setIsLoading(true);
    const loadPresetsFromContract = async () => {
      try {
        const resOpen =
          await contract.contractWrapper.readContract.functions.open();
        setOpen(resOpen[0]);
        const resHarvested =
          await contract.contractWrapper.readContract.functions.harvested();
        setHarvested(resHarvested[0]);
        const resMaxPerMint =
          await contract.contractWrapper.readContract.functions.maxPerMint();
        setMaxPerMint(resMaxPerMint[0]);
        const resMaxPerWallet =
          await contract.contractWrapper.readContract.functions.maxPerWallet();
        setMaxPerWallet(resMaxPerWallet[0]);
        const resRevealed =
          await contract.contractWrapper.readContract.functions.revealed();
        setRevealed(resRevealed[0]);
        const resCost =
          await contract.contractWrapper.readContract.functions.cost();
        const biCost = ethers.BigNumber.from(resCost[0]);
        setCost(biCost / 1000000000000000000);
        const resTotalSupply =
          await contract.contractWrapper.readContract.functions.totalSupply();
        setTotalSupply(resTotalSupply[0]);
        const resSupply =
          await contract.contractWrapper.readContract.functions.supply();
        setSupply(resSupply[0]);
        const resCommission =
          await contract.contractWrapper.readContract.functions.getCommission({
            from: account[0].data.address,
          });
        setCommission(resCommission[0]);
        const resOwner =
          await contract.contractWrapper.readContract.functions.owner();
        setOwner(resOwner[0]);
        const resBaseUri =
          await contract.contractWrapper.readContract.functions.getBaseUri();
        setBaseUri(resBaseUri[0]);

        const providerForContractBalance = new ethers.providers.Web3Provider(
          window.ethereum
        );
        const resBalance = await providerForContractBalance.getBalance(
          contractAddress
        );
        const balanceInEth = ethers.utils.formatEther(resBalance);
        setBalance(balanceInEth);

        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    contract && account[0].data && loadPresetsFromContract();
  }, [account, contract, contractAddress]);

  useEffect(() => {
    const loadHarvestDataFromJSON = async () => {
      const resHarvestData = await loadHarvestData();
      const {
        kilosPerLiter,
        kilosPerTree,
        percentageGreen,
        percentageWhite,
        literPrice,
        totalKilosHarvested,
      } = resHarvestData.HarvestingData;
      setHarvestKilosPerLiter(kilosPerLiter);
      setHarvestKilosPerTree(kilosPerTree);
      setHarvestPercentageGreen(percentageGreen);
      setHarvestPercentageWhite(percentageWhite);
      setHarvestPricePerLiter(literPrice);
      setHarvestTotalKilosHarvested(totalKilosHarvested);
    };
    loadHarvestDataFromJSON();
  }, []);

  const toggleMint = async () => {
    try {
      const result =
        await contract.contractWrapper.writeContract.functions.setOpen(!open, {
          from: account[0].data.address,
        });
      console.log(result);
      setOpen(!open);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleHarvested = async () => {
    try {
      const result =
        await contract.contractWrapper.writeContract.functions.toggleHarvestStatus(
          { from: account[0].data.address }
        );
      console.log(result);
      setHarvested(!harvested);
    } catch (error) {
      console.log(error);
    }
  };

  const reveal = async () => {
    try {
      const result =
        await contract.contractWrapper.writeContract.functions.reveal({
          from: account[0].data.address,
        });
      console.log(result);
      setRevealed(!revealed);
    } catch (error) {
      console.log(error);
    }
  };

  const setCostPerMint = async () => {
    try {
      const result =
        await contract.contractWrapper.writeContract.functions.setCost(
          ethers.utils.parseEther(cost.toString()),
          { from: account[0].data.address }
        );
      console.log(result);
    } catch (error) {
      console.error(error);
    }
  };

  const setCommissionPercentage = async () => {
    try {
      const result =
        await contract.contractWrapper.writeContract.functions.setCommission(
          commission,
          { from: account[0].data.address }
        );
      console.log(result);
    } catch (error) {
      console.error(error);
    }
  };

  const withdraw = async () => {
    try {
      const result =
        await contract.contractWrapper.writeContract.functions.withdraw({
          from: account[0].data.address,
        });
      console.log(result);
      window.location.assign(
        window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port === 80)
          ? "/"
          : ":" + window.location.port
      );
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNFTWalletHolders = async () => {
    try {
      if (account[0].data && contractAddress && supply) {
        document.getElementById("btnFetchNFTWalletHolders").innerHTML =
          "Loading...";
        const resNFTOwnedWallets =
          await contract.contractWrapper.readContract.functions.getOverviewOfAllOwnersWithTokenAmount(
            contractAddress,
            supply
          );
        setNFTsOwnedWallets(
          resNFTOwnedWallets[0].substring(0, resNFTOwnedWallets[0].length - 1)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBTCWalletHolders = async () => {
    try {
      if (account && contractAddress && supply) {
        document.getElementById("btnFetchBTCWalletHolders").innerHTML =
          "Loading...";
        const resBTCWallets =
          await contract.contractWrapper.readContract.functions.getAllBTCWalletAddresses(
            contractAddress,
            supply
          );
        setBTCWalletHolders(
          resBTCWallets[0].substring(0, resBTCWallets[0].length - 1)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setContractBaseUri = async () => {
    try {
      const result =
        await contract.contractWrapper.writeContract.functions.setBaseUri(
          baseUri,
          { from: account[0].data.address }
        );
      console.log(result);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading && <div className="nft-minter">Loading...</div>}
      {!isLoading && account[0].data && owner === account[0].data.address && (
        <div>
          <div className="admin-panel admin-content">
            <div className="admin-panel-card">
              <h3>Statistics</h3>
              <p>
                Already minted: {supply} of {totalSupply}
                <br />
                Max per mint: {maxPerMint}
                <br />
                Max per wallet: {maxPerWallet}
                <br />
                Floor price: {cost} MATIC
                <br />
                Commission: {commission / 10}%<br />
              </p>
            </div>
            <div className="admin-panel-card">
              <h3>Contract status</h3>
              <p>
                Open:{" "}
                {open ? (
                  <input type="checkbox" checked onChange={toggleMint} />
                ) : (
                  <input type="checkbox" onChange={toggleMint} />
                )}
                <br />
                Revealed:{" "}
                {revealed ? (
                  <input type="checkbox" checked onChange={reveal} />
                ) : (
                  <input type="checkbox" onChange={reveal} />
                )}
                <br />
                Harvested:{" "}
                {harvested ? (
                  <input type="checkbox" checked onChange={toggleHarvested} />
                ) : (
                  <input type="checkbox" onChange={toggleHarvested} />
                )}
              </p>
            </div>
            <div className="admin-panel-card">
              <h3>Contract balance</h3>
              <p>
                <span>{balance} MATIC</span>
                <button className="admin-panel-card-button" onClick={withdraw}>
                  Withdraw
                </button>
              </p>
            </div>
            <div className="admin-panel-card">
              <h3>Contract settings</h3>
              <p>
                <input
                  className="amin-panel-card-input"
                  value={baseUri}
                  onChange={(e) => setBaseUri(e.target.value)}
                />
                <button
                  className="admin-panel-card-button"
                  onClick={setContractBaseUri}
                >
                  Set baseUri
                </button>
                <br />
                <input
                  className="admin-panel-card-input-number"
                  type="number"
                  value={cost}
                  onChange={(e) => setCost(e.target.value)}
                />
                MATIC
                <button
                  className="admin-panel-card-button"
                  onClick={setCostPerMint}
                >
                  Set Floor price
                </button>
                <br />
                <input
                  className="admin-panel-card-input-number"
                  type="number"
                  value={commission}
                  onChange={(e) => setCommission(e.target.value)}
                />
                = {commission / 10}%
                <button
                  className="admin-panel-card-button"
                  onClick={setCommissionPercentage}
                >
                  Set Commission
                </button>
                <br />
              </p>
            </div>
            <div className="admin-panel-card">
              <h3>Harvesting settings (from Harvesting.json)</h3>
              <p>
                Kilo's per liter: {harvestKilosPerLiter} kg/l
                <br />
                Kilo's per tree: {harvestKilosPerTree} kg/tree
                <br />
                Profit % Green olives: {harvestPercentageGreen}%<br />
                Profit & White olives: {harvestPercentageWhite}%<br />
                Price per liter oil: {harvestPricePerLiter} EUR/l
                <br />
                Total kilo's harvested: {harvestTotalKilosHarvested} kg
                <br />
                Total earnings:{" "}
                {(harvestTotalKilosHarvested / harvestKilosPerLiter) *
                  harvestPricePerLiter}{" "}
                EUR
              </p>
            </div>

            <div className="admin-panel-card">
              <h3>NFT holders wallet addresses and # of NFTree's</h3>
              {!nftsOwnedWallets && (
                <button
                  id="btnFetchNFTWalletHolders"
                  className="admin-panel-card-button"
                  onClick={fetchNFTWalletHolders}
                >
                  Fetch...
                </button>
              )}
              {nftsOwnedWallets && (
                <NFTHolderList nftHolders={nftsOwnedWallets} />
              )}
            </div>

            <div className="admin-panel-card">
              <h3>BTC wallets of NFTree holders</h3>
              {!btcWalletHolders && (
                <button
                  id="btnFetchBTCWalletHolders"
                  className="admin-panel-card-button"
                  onClick={fetchBTCWalletHolders}
                >
                  Fetch...
                </button>
              )}
              {btcWalletHolders && (
                <BTCWalletHolderList btcWalletHolders={btcWalletHolders} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminPanel;
