const VerticalCarousel = () => {
    return ( 
        <div className="wrapperCarousel" style={{ background: 'url(/olive-orchard.jpg)'}}>
      <div className="carousel">
        <div className="carousel__item">
          <div className="carousel__item-head"></div>
          <div className="carousel__item-body">
            <p className="title">White olive NFTree</p>
            <p>The quintessence of rarity</p>
          </div>
        </div>
        <div className="carousel__item">
          <div className="carousel__item-head"></div>
          <div className="carousel__item-body">
            <p className="title">Proof of Harvest</p>
            <p>Unique way to invest into preservation and harvesting</p>
          </div>
        </div>
        <div className="carousel__item">
          <div className="carousel__item-head"></div>
          <div className="carousel__item-body">
            <p className="title">Multichain interoperability</p>
            <p>Invest in NFT, collect in BTC</p>
          </div>
        </div>
        <div className="carousel__item">
          <div className="carousel__item-head"></div>
          <div className="carousel__item-body">
            <p className="title">Seasonal NFT</p>
            <p>Your NFT changes with the seasons</p>
          </div>
        </div>
        <div className="carousel__item">
          <div className="carousel__item-head"></div>
          <div className="carousel__item-body">
            <p className="title">Option to stake</p>
            <p>Stake your NFTree for additional harvesting seasons</p>
          </div>
        </div>
        <div className="carousel__item">
          <div className="carousel__item-head"></div>
          <div className="carousel__item-body">
            <p className="title">White olive NFTree</p>
            <p>The quintessence of rarity</p>
          </div>
        </div>
        <div className="carousel__item">
          <div className="carousel__item-head"></div>
          <div className="carousel__item-body">
            <p className="title">Proof of Harvest</p>
            <p>Preserve the trees, reap the benefits from harvesting</p>
          </div>
        </div>
        <div className="carousel__item">
          <div className="carousel__item-head"></div>
          <div className="carousel__item-body">
            <p className="title">Multichain interoperability</p>
            <p>Invest in NFT, collect in BTC</p>
          </div>
        </div>
        <div className="carousel__item">
          <div className="carousel__item-head"></div>
          <div className="carousel__item-body">
            <p className="title">Seasonal NFT</p>
            <p>Your NFT changes with the seasons</p>
          </div>
        </div>
      </div>
    </div>
     );
}
 
export default VerticalCarousel;