import { ConnectWallet } from "@thirdweb-dev/react";
import { Link } from "react-router-dom";
import AdminLink from "./AdminLink";

const Navbar = (props) => {
  const { contractAddress, account } = props;
  const mobileNav = document.querySelector(".mobile-nav");

  const handleOpenMobileNavClick = (e) => {
    mobileNav.classList.add("open");
  };

  const handleCloseMobileNavClick = (e) => {
    mobileNav.classList.remove("open");
  };

  return (
    <>
      <header className="main-header">
        <div>
          <button className="toggle-button" onClick={handleOpenMobileNavClick}>
            <span className="toggle-button__bar"></span>
            <span className="toggle-button__bar"></span>
            <span className="toggle-button__bar"></span>
          </button>
          <Link to="/" className="main-header__brand">
            <img
              src="/olive-nftree-logo.png"
              style={{ display: "inline-block", verticalAlign: "middle" }}
            />
            Olive NFTree
          </Link>
        </div>
        <nav className="main-nav">
          <ul className="main-nav__items">
            <li className="main-nav__item">
              <Link to="/about">About us</Link>
            </li>
            <li className="main-nav__item">
              <Link to="/faq">FAQ</Link>
            </li>
            <li className="main-nav__item">
              <Link to="/gallery">Gallery</Link>
            </li>
            <li className="main-nav__item">
              <Link to="/buy-nft">Buy NFT</Link>
            </li>
            <li className="main-nav__item">
              <Link to="/my-nfts">My NFTree's</Link>
            </li>
            <li className="main-nav__item">
              <AdminLink
                contractAddress={contractAddress}
                account={account}
                handleClick={handleCloseMobileNavClick}
              />
            </li>
            <li className="main-nav__item connect">
              <ConnectWallet accentColor="#33FF96" colorMode="light" />
            </li>
          </ul>
        </nav>
      </header>
      <nav className="mobile-nav">
        <ul className="mobile-nav__items">
          <li className="mobile-nav__item">
            <Link to="/" onClick={handleCloseMobileNavClick}>
              Home
            </Link>
          </li>
          <li className="mobile-nav__item">
            <Link to="/about" onClick={handleCloseMobileNavClick}>
              About us
            </Link>
          </li>
          <li className="mobile-nav__item">
            <Link to="/faq" onClick={handleCloseMobileNavClick}>
              FAQ
            </Link>
          </li>
          <li className="mobile-nav__item">
            <Link to="/gallery" onClick={handleCloseMobileNavClick}>
              Gallery
            </Link>
          </li>

          <li className="mobile-nav__item">
            <Link to="/buy-nft" onClick={handleCloseMobileNavClick}>
              Buy NFT
            </Link>
          </li>
          <li className="mobile-nav__item">
            <Link to="/my-nfts" onClick={handleCloseMobileNavClick}>
              My NFTree's
            </Link>
          </li>
          <li className="mobile-nav__item">
            <AdminLink
              contractAddress={contractAddress}
              account={account}
              handleClick={handleCloseMobileNavClick}
            />
          </li>
          <li className="mobile-nav__item connect">
            <ConnectWallet accentColor="#33FF96" colorMode="light" />
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
