import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useSDK } from "@thirdweb-dev/react";
import contractABI from "../../contracts/GenerateNFTOnlinePresaleReveal.json";

const NFTMinter = (props) => {
  const { contractAddress, account } = props;
  const [mintNumber, setMintNumber] = useState(1);
  const sdk = useSDK();
  const [contract, setContract] = useState();

  useEffect(() => {
    const initContract = async () => {
      const contract = await sdk.getContractFromAbi(
        contractAddress,
        contractABI.abi
      );
      setContract(contract);
    };
    sdk && initContract();
  }, [sdk, contractAddress]);

  const mintNFT = async () => {
    if (contract) {
      try {
        const res =
          await contract.contractWrapper.readContract.functions.cost();
        const result = res[0];
        const biResult = ethers.BigNumber.from(result);
        const costTimesMint = (biResult * mintNumber) / 1000000000000000000;
        const resWrite =
          await contract.contractWrapper.writeContract.functions.mint(
            mintNumber,
            {
              from: account[0].data.address,
              value: ethers.utils.parseEther(costTimesMint.toString()),
            }
          );
        console.log(resWrite);
        window.location.assign(
          window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port === 80)
            ? "/"
            : ":" + window.location.port
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {!account[0].data && (
        <div className="nft-minter">
          Connect your wallet to Polygon Mainnet first get started!
        </div>
      )}
      {account[0].data && contract && (
        <div className="nft-minter">
          <h2>Mint your own NFTree's for 1331 MATIC (approx 0.7 ETH)</h2>
          <p>
            You can buy an NFTree through the so-called minting process. Once
            you click on the Mint NFTree button, the desired amount of NFTree's
            will be created and added to your wallet.
            <br />
            Since the NFTree's are NFT's in nature you can also view them on{" "}
            <a href="https://opensea.io/" target="_blank" rel="noreferrer">
              OpenSea
            </a>{" "}
            or any other NFT marketplace.
            <br />
            You can mint up to 5 NFTree's in one go. We have a maximum of 20
            NFTree's per wallet holder.
          </p>
          Mint{" "}
          <input
            className="nft-minter-input"
            type="number"
            min={1}
            max={5}
            value={mintNumber}
            onChange={(e) => setMintNumber(e.target.value)}
          />{" "}
          NFTree's
          <button className="nft-minter-button" onClick={mintNFT}>
            Mint NFTree - 1331 MATIC
          </button>
        </div>
      )}
    </>
  );
};

export default NFTMinter;
