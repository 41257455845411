
export const loadNFT = async (tokenID) => {
    let nft = null;
    try {
        const res = await fetch(`/NFTs/json/${tokenID}.json`);
        nft = await res.json();
    }
    catch(error) {
        console.error("Cannot load NFT", error);
    }
    return nft;
}