import Footer from "./components/ui/Footer";
import GrowingTree from "./components/ui/GrowingTree";

const HomePage = () => {

  return (
    <>
      <GrowingTree />
      <Footer />
    </>
  );
}
 
export default HomePage;
