import { useEffect, useState } from "react";
import { useSDK } from "@thirdweb-dev/react";
import contractABI from "../../contracts/GenerateNFTOnlinePresaleReveal.json";

const BTCWallet = (props) => {
  const { tokenID, account, contractAddress } = props;
  const [btcWallet, setBTCWallet] = useState("");
  const [newBTCWallet, setNewBTCWallet] = useState("");
  const sdk = useSDK();
  const [contract, setContract] = useState();

  useEffect(() => {
    const initContract = async () => {
      const contract = await sdk.getContractFromAbi(
        contractAddress,
        contractABI.abi
      );
      setContract(contract);
    };
    sdk && initContract();
  }, [sdk, contractAddress]);

  const storeNewBTCWalletAddress = async () => {
    try {
      const result =
        await contract.contractWrapper.writeContract.functions.setBTCWalletAddress(
          newBTCWallet,
          { from: account[0].data.address }
        );
      console.log(result);
      setBTCWallet(newBTCWallet);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getBTCWallet = async () => {
      if (account[0].data && contract) {
        const resBTCWallet =
          await contract.contractWrapper.readContract.functions.getBTCWalletAddress(
            { from: account[0].data.address }
          );
        setBTCWallet(resBTCWallet);
      }
    };
    getBTCWallet();
  }, [tokenID, account, contract]);

  return (
    <div className="btc-wallet">
      <h2>BTC Wallet</h2>
      {btcWallet === "" ? (
        <p>
          You can enter your BTC Wallet address here so we know where to send
          the profits to. No BTC Wallet address entered means no profits...
        </p>
      ) : (
        <p>
          You have stored this BTC Wallet address as yours:{" "}
          <strong>{btcWallet}</strong>
        </p>
      )}
      <input
        className="btc-wallet-input"
        placeholder="Enter your BTC Wallet Address..."
        onChange={(e) => setNewBTCWallet(e.target.value)}
      />
      {btcWallet === "" ? (
        <button
          className="btc-wallet-button"
          onClick={storeNewBTCWalletAddress}
        >
          Save BTC Address
        </button>
      ) : (
        <button
          className="btc-wallet-button"
          onClick={storeNewBTCWalletAddress}
        >
          Update BTC Address
        </button>
      )}
    </div>
  );
};

export default BTCWallet;
