import { useEffect, useState } from "react";
import BTCWallet from "./components/ui/BTCWallet";
import NFTree from "./components/ui/NFTree";
import { loadHarvestData } from "./utils/load-harvest-data";
import { useBTCPrice } from "./components/hooks/useBTCPrice";
import { ethers } from "ethers";
import { useSDK } from "@thirdweb-dev/react";
import contractABI from "./contracts/GenerateNFTOnlinePresaleReveal.json";

const MyNFTsPage = (props) => {
  const { contractAddress, account } = props;
  const [supply, setSupply] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [revealed, setRevealed] = useState(false);
  const [walletHoldsTokenAmount, setWalletHoldsTokenAmount] = useState(0);
  const [nftsOwnedTokenIDs, setNFTsOwnedTokenIDs] = useState("");
  const [harvestKilosPerLiter, setHarvestKilosPerLiter] = useState(0);
  const [harvestKilosPerTree, setHarvestKilosPerTree] = useState(0);
  const [harvestPercentageGreen, setHarvestPercentageGreen] = useState(0);
  const [harvestPercentageWhite, setHarvestPercentageWhite] = useState(0);
  const [harvestPricePerLiter, setHarvestPricePerLiter] = useState(0);
  const { btc } = useBTCPrice();
  const [isLoading, setIsLoading] = useState(false);

  const sdk = useSDK();
  const [contract, setContract] = useState();

  useEffect(() => {
    const initContract = async () => {
      const contract = await sdk.getContractFromAbi(
        contractAddress,
        contractABI.abi
      );
      setContract(contract);
    };
    sdk && initContract();
  }, [sdk, contractAddress]);

  useEffect(() => {
    setIsLoading(true);
    const loadNFTs = async () => {
      try {
        if (contractAddress && contract) {
          const resTotalSupply =
            await contract.contractWrapper.readContract.functions.totalSupply();
          setTotalSupply(resTotalSupply[0]);
          const resSupply =
            await contract.contractWrapper.readContract.functions.supply();
          const resRevealed =
            await contract.contractWrapper.readContract.functions.revealed();
          setRevealed(resRevealed);
          setSupply(resSupply[0]);
          if (account[0].data && contractAddress) {
            const resWalletHoldsTokenAmount =
              await contract.contractWrapper.readContract.functions.walletHoldsTokenAmount(
                account[0].data.address,
                contractAddress
              );
            setWalletHoldsTokenAmount(
              ethers.BigNumber.from(resWalletHoldsTokenAmount[0]).toNumber()
            );
          }
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    contract && account[0].data && loadNFTs();
  }, [contract, contractAddress, account]);

  useEffect(() => {
    const fetchOwnedNFTs = async () => {
      try {
        if (account[0].data && contractAddress && supply) {
          const resWalletHoldsTokens =
            await contract.contractWrapper.readContract.functions.walletIsOwnerOfToken(
              account[0].data.address,
              contractAddress,
              supply
            );
          setNFTsOwnedTokenIDs(
            resWalletHoldsTokens[0].substring(
              0,
              resWalletHoldsTokens[0].length - 1
            )
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchOwnedNFTs();
  }, [contract, account, contractAddress, supply, nftsOwnedTokenIDs]);

  useEffect(() => {
    const loadHarvestDataFromJSON = async () => {
      const resHarvestData = await loadHarvestData();
      const {
        kilosPerLiter,
        kilosPerTree,
        percentageGreen,
        percentageWhite,
        literPrice,
      } = resHarvestData.HarvestingData;
      setHarvestKilosPerLiter(kilosPerLiter);
      setHarvestKilosPerTree(kilosPerTree);
      setHarvestPercentageGreen(percentageGreen);
      setHarvestPercentageWhite(percentageWhite);
      setHarvestPricePerLiter(literPrice);
    };
    loadHarvestDataFromJSON();
  }, []);

  return (
    <>
      {!account[0].data && (
        <div className="nft-minter">
          Connect your wallet to Polygon Mainnet first get started!
        </div>
      )}
      {account[0].data && isLoading && (
        <div className="nft-minter">Loading...</div>
      )}

      {!isLoading && (
        <div>
          {account[0].data && walletHoldsTokenAmount > 0 && (
            <BTCWallet account={account} contractAddress={contractAddress} />
          )}
          {account[0].data && (
            <div className="nft-wrapper">
              <h2>Number of NFTree's owned by you: {walletHoldsTokenAmount}</h2>
              <br />
              <div className="nft-content">
                {nftsOwnedTokenIDs &&
                  nftsOwnedTokenIDs
                    .split(",")
                    .map((tokenID) => (
                      <NFTree
                        key={tokenID}
                        tokenID={tokenID}
                        account={account}
                        contractAddress={contractAddress}
                        totalSupply={totalSupply}
                        harvestKilosPerLiter={harvestKilosPerLiter}
                        harvestKilosPerTree={harvestKilosPerTree}
                        harvestPercentageGreen={harvestPercentageGreen}
                        harvestPercentageWhite={harvestPercentageWhite}
                        harvestPricePerLiter={harvestPricePerLiter}
                        btcPrice={btc}
                        revealed={revealed}
                      />
                    ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MyNFTsPage;
