import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSDK } from "@thirdweb-dev/react";
import contractABI from "../../contracts/GenerateNFTOnlinePresaleReveal.json";

const AdminLink = (props) => {
  const { contractAddress, account, handleClick } = props
  const [owner, setOwner] = useState(null);
  const sdk = useSDK();
  const [contract, setContract] = useState();

useEffect(() => {
  const initContract = async () => {
    const contract = await sdk.getContractFromAbi(
      contractAddress,
      contractABI.abi
    );    
    setContract(contract);    
  }
  sdk && initContract();
}, [sdk, contractAddress])


  useEffect(() => {
    const loadOwnerFromContract = async () => {
      try {
        const resOwner = await contract.contractWrapper.readContract.functions.owner();
        setOwner(resOwner[0]);
      } catch (error) {
        console.error(error);
      }
    };
    contract && loadOwnerFromContract();
  }, [contract]);

  return (
    <>
      {account[0].data && owner === account[0].data.address && (
        <Link to="/admin" onClick={handleClick}>
          Admin
        </Link>
      )}
    </>
  );
};

export default AdminLink;
