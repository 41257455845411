const BTCWalletHolderList = (props) => {
    const { btcWalletHolders} = props;
    const arr = btcWalletHolders.split(",");
    const map = arr.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
    const arrHolders = [...map.keys()];
    const arrAmounts = [...map.values()]

    return ( 
        <div id="BTCHolders">
            <table>
                <thead>
                    <tr className="tr-head">
                        <th>#</th>
                        <th>ETH Wallet address</th>
                        <th>BTC address</th>
                        <th># of NFTree's</th>
                    </tr>
                </thead>
                <tbody>
                {arrHolders.map((holder, i) => 
                    
                    <tr key={holder} className="tr-body-even">
                        <td>{i+1}</td>
                        <td>{holder.split("|")[0]}</td>
                        <td>{(holder.split("|")[1]).length === 0 ? "UNKNOWN ADDRESS" : holder.split("|")[1]}</td>
                        <td style={{textAlign:'center'}}>{arrAmounts[i]}</td>
                    </tr> 
                    )
                }
                </tbody>
            </table>
        </div>
    );
}
 
export default BTCWalletHolderList;
