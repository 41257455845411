import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ChainId, ThirdwebProvider } from "@thirdweb-dev/react";

// This is the chainId your dApp will work on.
const activeChainId = ChainId.Polygon;

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ThirdwebProvider
    desiredChainId={activeChainId}
    supportedChains={[ChainId.Polygon]}
    sdkOptions={{
      readonlySettings: {
        chainId: ChainId.Polygon,
      },
    }}
  >
    <App />
  </ThirdwebProvider>
);
