import useSWR from "swr";

const URL = "https://api.coingecko.com/api/v3/coins/bitcoin?localization=false&tickers=false&community_data=false&developer_data=false&sparkline=false"

const fetcher = async url => {
  const res = await fetch(url)
  const json = await res.json()

  return json.market_data.current_price.eur ?? null
  
}

export const useBTCPrice = () => {
  const { data } = useSWR(
    URL,
    fetcher,
    { refreshInterval: 10000 }
  )

  return { 
            btc: data
         };
}

