import { useAccount } from "@thirdweb-dev/react";
import "./styles/index.css";
import Navbar from "./components/ui/Navbar";
import HomePage from "./HomePage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutUsPage from "./AboutUsPage";
import BuyNFTPage from "./BuyNFTPage";
import MyNFTsPage from "./MyNFTsPage";
import PageNotFound from "./PageNotFound";
import AdminPanel from "./components/ui/AdminPanel";
import FAQPage from "./FAQPage";
import RoadmapPage from "./RoadmapPage";
import GalleryPage from "./GalleryPage";

const CONTRACT_ADDRESS = "0x678deb0fE781B9E8cEcF806195eFB2554F0C3490";

export default function Home() {
  const account = useAccount();

  return (
    <Router>
      <div className="App">
        <header className="main-header">
          <Navbar contractAddress={CONTRACT_ADDRESS} account={account} />
        </header>
        <main>
          <div className="content">
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route path="/about">
                <AboutUsPage />
              </Route>
              <Route path="/faq">
                <FAQPage />
              </Route>
              <Route path="/gallery">
                <GalleryPage />
              </Route>
              <Route path="/buy-nft">
                <BuyNFTPage
                  contractAddress={CONTRACT_ADDRESS}
                  account={account}
                />
              </Route>
              <Route path="/my-nfts">
                <MyNFTsPage
                  contractAddress={CONTRACT_ADDRESS}
                  account={account}
                />
              </Route>
              <Route path="/admin">
                <AdminPanel
                  contractAddress={CONTRACT_ADDRESS}
                  account={account}
                />
              </Route>
              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          </div>
        </main>
      </div>
    </Router>
  );
}
