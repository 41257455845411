import { useEffect, useState } from "react"
import { loadNFT } from "../../utils/load-nft";

const NFTree = (props) => {
    const { tokenID,  
            account, 
            contractAddress, 
            totalSupply, 
            harvestKilosPerLiter, 
            harvestKilosPerTree, 
            harvestPercentageGreen, 
            harvestPercentageWhite,
            harvestPricePerLiter,
            btcPrice,
            revealed
        } = props;
    const [nftImage, setNFTImage] = useState('');
    const [flipsideNFT, setFlipsideNFT] = useState('');
    const [nftTreeType, setNFTreeType] = useState('');
    const [rarity, setRarity] = useState('');
    const [percentage, setPercentage] = useState('');

    const fetchNFTBasedOnTokenID = async (tokenID) => {
        const resMyNFT = await loadNFT(tokenID);
        
        return {
            image: resMyNFT.image,
            name: resMyNFT.name,
            description: resMyNFT.description,
            ...resMyNFT
        }
    }

    useEffect(() => {
        const getNFTree = async() => {
            if (account[0].data && contractAddress) {
                const nftData = await fetchNFTBasedOnTokenID(tokenID);
                const { image } = nftData;    
                if (revealed) {
                    setNFTImage( image.replace('ipfs://NewUriToReplace', '/NFTs/images'));   
                    if (nftData.attributes[1].value === 'WhiteOlive') {
                        setFlipsideNFT('/NFTs/images/WhiteOliveBack.jpeg');
                        setNFTreeType('White');
                        setRarity('Rare');
                        setPercentage(harvestPercentageWhite);
                    
                    }  
                    else {
                        setFlipsideNFT('/NFTs/images/GreenOliveBack.jpeg')
                        setNFTreeType('Green');
                        setRarity('Normal');
                        setPercentage(harvestPercentageGreen);
                    }
                }
                else {
                    setNFTImage('/NFTs/Reveal.png');
                    setFlipsideNFT('/NFTs/Reveal.png');
                }
            }
        }
        getNFTree();
    }, [tokenID, account, contractAddress, harvestKilosPerLiter, harvestKilosPerTree, harvestPercentageGreen, harvestPercentageWhite, harvestPricePerLiter, btcPrice, revealed]) 


    return ( 

        <div className="card" key={tokenID}>
            <div className="card__side card__side--front" style={{ backgroundImage: `url(${nftImage})`, backgroundSize: `24.1em`}}>
                <div className="nft-card-content" >
                </div>
            </div>
            <div className="card__side card__side--back"  style={{ backgroundImage: `url(${flipsideNFT})`, backgroundSize: `24.1em`}}>
            { revealed &&
                <div className="nft-card-content" >
                    
                   <h2 className="nft-card-tokenid">#{String(tokenID).padStart(4, '0')}</h2>
                   <span className="nft-card-nft-type">{nftTreeType}</span>
                   <span className="nft-card-expected-kilos">{harvestKilosPerTree} kg</span>
                   <span className="nft-card-kilos-per-liter">{harvestKilosPerLiter} kg/liter</span>
                   <span className="nft-card-rarity">{rarity}</span>
                   <span className="nft-card-total-trees">{totalSupply} trees</span>
                   <span className="nft-card-potential-oil">{harvestKilosPerTree/harvestKilosPerLiter} liter</span>
                   <span className="nft-card-price-per-liter">{harvestPricePerLiter} EUR</span>
                   <span className="nft-card-percentage-reward">{percentage}%</span>
                   <span className="nft-card-btc-price">in BTC</span>
                   <span className="nft-card-btc-profit">
                        {((percentage/100)*(harvestPricePerLiter * (harvestKilosPerTree/harvestKilosPerLiter))/btcPrice).toFixed(8)}  BTC<br/>
                        approx. {((percentage/100)*(harvestPricePerLiter * (harvestKilosPerTree/harvestKilosPerLiter))).toFixed(2)} EUR 
                   </span> 
                </div>
            }   
            </div>
        </div>
    );
}
 
export default NFTree;
