import FAQ from "./components/ui/FAQ";

const FAQPage = () => {
  return (
    <section>
      <h2>Frequently Asked Questions</h2>
      <br />

      <div className="faq-content">
        <FAQ
          faqID="q1"
          faqTitle="Why should I buy an OliveNFTree?"
          faqText="The OliveNFTree is one of the only NFTs connected to real world passive income earning assets, the olive tree. The OliveNFTree gives you value in three ways: 1. Holding the NFT in your wallet which you can sell again at anytime you like. 2. Yearly passive income from the harvest paid out in BTC. 3. Carbon credits from the olive tree"
        />
        <FAQ
          faqID="q2"
          faqTitle="When do I get my harvest rewards?"
          faqText="Your harvest rewards are to be airdropped to your registered BTC wallet within 30 days after the Harvest, please check your NFT for the live data will show you."
        />
        <FAQ
          faqID="q3"
          faqTitle="What is Proof of Harvest?"
          faqText="We are providing Proof that Harvest has been completed by inputting all data onto the blockchain…. "
        />
        <FAQ
          faqID="q4"
          faqTitle="How do we know the trees are real and not some scam?"
          faqText="We are in the process of registering our trees with a company that specializes in carbon credits. This company uses satellite images to take pictures and calculates the biomass. This information is stored on chain also"
        />
        <FAQ
          faqID="q5"
          faqTitle="How do I buy an Olive NFTree?"
          faqText="Connect your MetaMask or other Web3 wallet to the Polygon mainnet and make sure you have at least 1331 MATIC. Press Mint."
        />
        <FAQ
          faqID="q6"
          faqTitle="I only have ETH on ETH Mainnet. How do I get Polygon MATIC?"
          faqText="Connect your MetaMask or other Web3 wallet to your ETH mainnet and go to https://app.uniswap.org/#/swap . Swap ETH for MATIC. Then go to https://wallet.polygon.technology/polygon/bridge/deposit and bridge your MATIC from ETH Mainnet to Polygon Mainnet"
        />
        <FAQ
          faqID="q7"
          faqTitle="Can I send my NFT to another wallet?"
          faqText="Of course just like a crypto currency it can be sent to another holding digital wallet."
        />
        <FAQ
          faqID="q8"
          faqTitle="Why the polygon network?"
          faqText="No congestion and near to no gas fees is our priority. With Polygon network the transaction are most efficient and cost effective. Especially during a bull market you will notice that gasfees on ETH are extremely high"
        />
        <FAQ
          faqID="q9"
          faqTitle="Can I sell my OliveNFTree?"
          faqText="Of course, you can list your NFT with any credible NFT market place such as Blur, Opensea or Rarible"
        />
        <FAQ
          faqID="q10"
          faqTitle="How to change my stored BTC wallet address?"
          faqText="Go to the myNFTree's page on our website and edit the field that shows the BTC wallet address. Press the button and confirm the transaction in your wallet"
        />
        <FAQ
          faqID="q11"
          faqTitle="How are the profits per tree calculated. What are the numbers on the flipside of the NFT?"
          faqText="The numbers on the backside of the NFTree will be updated based upon the actual kilos of olives that have been harvested. So every year this number will change accordingly."
        />
        <FAQ
          faqID="q12"
          faqTitle="Who profits from this?"
          faqText="We all do. Local experts perform the maintenance. The costs of maintenance is covered by the initial purchase of the NFTree. It covers the maintenance for the next 5 years. A highly skilled crew of local olive collectors will come and harvest the olives. These harvesting costs are also covered by sales of NFTs.
Once the harvest is taken we must pay for the olives to be procured into oil. We will sell the oil and all profits are shared between everybody involved with the process."
        />
      </div>
    </section>
  );
};

export default FAQPage;
