const FAQ = (props) => {
  const { faqID, faqTitle, faqText } = props;

  return (
    <div className="faq-question">
      <input id={faqID} type="checkbox" className="faq-panel" />
      <div className="faq-plus">+</div>
      <label htmlFor={faqID} className="faq-panel-title">
        {faqTitle}
      </label>
      <div className="faq-panel-content">{faqText}</div>
    </div>
  );
};

export default FAQ;
